import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 2em auto;
    padding: 0 2em;    
    a {
        margin: 0 1em;
    }
`;

const Footer = () => {
    return (
        <Wrapper>
            <div>

            </div>
            <div>
                <a href='https://github.com/seanvelasco/hl7viewer'>Source</a>
            </div>
        </Wrapper>
    )
}

export default Footer