import { createGlobalStyle } from 'styled-components'

export const theme = {
	header: 'rgba(45, 45, 45, 0.97)',
	text: 'rgba(255, 255, 255, 0.92)',
	background: '#1f1f1f;',
	link: 'darkblue',
	card: 'rgba(255, 255, 255, 0.1)',
	cardhover: 'rgba(255, 255, 255, 0.05)',
	footer: 'rgba(45, 45, 45, 0.97)',
}

const GlobalStyles = createGlobalStyle`
    a {
        color: ${theme.text}
    }
    

    h1 {
        margin: 0;
    }
    .landing {
        display: flex;
        padding: 0;
    }
    color: rgba(255, 255, 255, 0.9);
    .container {
        max-width: 900px;
        padding: 0 2em;
        margin: 0 margin;
    }

    header {
        background-color: ${theme.header}
    }
    .selection {
        display: flex;
        justify-content: right;
        button {
            width: 200px;
            margin: 0 0 0 2em;
        }
    }

`;

export default GlobalStyles