import styled from 'styled-components';
import { device } from '../constants/device';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
        span {
            color: darkgrey;
            :nth-of-type(even) {
                color: grey;
            }
        }
    }
    max-width: 1200px;
    margin: 1em auto;
    padding: 0 2em;

    .logo {
        display: flex;
        align-items: center;
        img {
            height: 2em;
            margin-right: 2em;
        }
        h1 {
        //    color: #1665ff;
            font-size: 2.3em;
        }
    }
    .full {
        display: none;
    }
   @media ${device.tablet} {
        .full {
            display: block;
        }
        .mobile {
            display: none;
        }
   }
`;

const Header = () => {
    return (
        <Wrapper>
                <div className='logo'>
                    <h1>HL7</h1>
                </div>
        </Wrapper>
    )
}

export default Header